.ui-container {
    display: flex;
    flex: 1;
    justify-content: center;
    .ui-container-sub {
        width: 1280px;
    }
}

.au-title {
    padding: 20px 40px;
    font-size: 48px;
    font-weight: bold;
}

iframe {
    width: 800px;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    border-right: none;
    border-radius: 4px;
}

@media screen and (max-width: 1280px) {
    .ui-container {
        .ui-container-sub {
            width: 100%;
        }
    }
}

@media screen and (max-width: 700px) {
    .au-title {
        padding: 10px 20px;
        font-size: 24px;
        font-weight: bold;
    }
    iframe {
        width: 100%;
        border: none;
        border-top: 1px solid #e8e8e8;
    }
}
