@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.lds-spinner {
    position: relative;
}
.lds-spinner div {
    left: 96px;
    top: 35px;
    position: absolute;
    -webkit-animation: lds-spinner linear 2s infinite;
    animation: lds-spinner linear 2s infinite;
    width: 8px;
    height: 38px;
    border-radius: 34%;
    -webkit-transform-origin: 4px 65px;
    transform-origin: 4px 65px;
}
.lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-delay: -1.833333333333333s;
    animation-delay: -1.833333333333333s;
}
.lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-animation-delay: -1.666666666666667s;
    animation-delay: -1.666666666666667s;
}
.lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-delay: -1.5s;
    animation-delay: -1.5s;
}
.lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation-delay: -1.333333333333333s;
    animation-delay: -1.333333333333333s;
}
.lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
    -webkit-animation-delay: -1.166666666666667s;
    animation-delay: -1.166666666666667s;
}
.lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: -0.833333333333333s;
    animation-delay: -0.833333333333333s;
}
.lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
    -webkit-animation-delay: -0.666666666666667s;
    animation-delay: -0.666666666666667s;
}
.lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-animation-delay: -0.333333333333333s;
    animation-delay: -0.333333333333333s;
}
.lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
    -webkit-animation-delay: -0.166666666666667s;
    animation-delay: -0.166666666666667s;
}
.lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.lds-spinner {
    width: 32px !important;
    height: 32px !important;
    -webkit-transform: translate(-16px, -16px) scale(0.16) translate(16px, 16px);
    transform: translate(-16px, -16px) scale(0.16) translate(16px, 16px);
}
