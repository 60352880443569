@use "node_modules/@material/data-table/data-table";

@import-normalize;

@include data-table.core-styles;
@include data-table.theme-baseline;

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary: #1565c0;
    --primary-light: #bbdefb;
    --accent: #03c2fd;
    --border-color: #ddd;
    --border-radius: 6px;
    --animate-duration: 400ms;
    --default-font: "Manrope", "Helvetica Neue", sans-serif;
    --toastify-color-progress-light: var(--primary);
    --toastify-color-dark: #333;
    --toastify-toast-min-height: 46px;
    --md-decelerate: .2s all cubic-bezier(0.0, 0.0, 0.2, 1);
    --md-accelerate: .2s all cubic-bezier(0.4, 0.0, 1, 1);
}

html, body {
    background: #fff;
}

.rounded {
    border-radius: var(--border-radius);
}

body {
    margin: 0;
    font-family: var(--default-font);
    font-size: 15px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MuiMenuItem-root {
    font-family: var(--default-font) !important;
    font-size: 13px;
}

#root {
    overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.Toastify__toast {
    font-size: 13px;
    font-weight: normal;
    /*letter-spacing: -0.3px;*/
    font-family: var(--default-font);
    border-radius: var(--border-radius);
}

.Toastify__toast-container {
    top: 80px;
    right: 15px;
}

.ReactModal__Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: all .3s ease-in-out;
    backdrop-filter: blur(10px);
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
    display: flex;
    border: none !important;
    background: transparent !important;
    justify-content: center;
    align-items: center;
    inset: 0 !important;
}

a {
    color: var(--primary);
    &:hover {
        text-decoration: underline;
    }
}

.color-primary {
    color: var(--primary);
}

.border-2 {
    border-color: #dddddd !important;
}

.modal-list {
    .modal-list-item {
        padding: 6px 10px;
        cursor: pointer;
        border-radius: var(--border-radius);
        font-size: 14px;
        font-weight: 400;
        &:hover {
            background: #eee;
        }
    }
}

@media screen and (max-width: 700px){
    .ui-home {
        .footer-btn-cont {
            display: none;
        }
    }
}
